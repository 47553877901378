import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "./style.css";

const VideoGallery = () => {
  return (
    <div className="intro">
      <div className="back">
        <img src="./images/back.jpg" className="w-100" alt="" />
        <div className="inner-head">
          <h3> Video Gallery</h3>
          <span> Video Gallery &gt;&gt; Video Gallery</span>
        </div>
      </div>
      <div className="intro-body pt-4 pb-4 p-md-5">
        <div className="col-lg-8 offset-lg-2 p-3">
          <div className="ihead">
            <h2 className="pt-4 pb-4"> Video Gallery</h2>
          </div>
          <div className="intro-content">
            <Carousel fade indicators={false}>
              <Carousel.Item>
                <iframe
                  className="videogallery w-100"
                  src="https://www.youtube.com/embed/uWfkrQrGCJc"
                  title="YouTube video player"
                  frameBorder={0}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                  autoPlay={false}
                />
              </Carousel.Item>
              <Carousel.Item>
                <iframe
                  className="videogallery w-100"
                  src="https://www.youtube.com/embed/GUjdQL3Mxlc"
                  title="YouTube video player"
                  frameBorder={0}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                  autoPlay={false}
                />
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoGallery;
