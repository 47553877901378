import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import AccessRoad from "./AccessRoad";
import Csp from "./Csp";
import Dpr from "./Dpr";
import Eia from "./Eia";
import GLiscense from "./GLiscense";
import Ppa from "./Ppa";
import PublicI from "./PublicI";
import SiteVisit from "./SiteVisit";
import Survey from "./Survay";
import Tls from "./Tls";
import "./style.css";
import Wfm from "./Wfm";

const InnerGallery = () => {
  // State to keep track of active tab
  const [activeTab, setActiveTab] = useState("site-visit");

  // Function to handle tab change
  const handleTabChange = (eventKey) => {
    setActiveTab(eventKey);
  };

  return (
    <div className="intro">
      <div className="back">
        <img src="./images/back.jpg" className="w-100" alt="" />
        <div className="inner-head">
          <h3> Photo Gallery</h3>
          <span>Photo Gallery &gt;&gt; Photo Gallery</span>
        </div>
      </div>
      <div className="intro-body  pt-4 pb-4 p-md-5">
        <Tabs
          activeKey={activeTab}
          onSelect={handleTabChange}
          defaultActiveKey="site-visit"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="site-visit" title="Site Visit">
            <h4 className="ps-2 pe-2">Site Visit</h4>
            <p className="ps-2 pe-2">
              The very initial step in development of hydropower involves
              physical site visit by promoters, developers and team of experts.
              The first site visit for development of Upper Sankhuwa Khola
              Hydroelectric Project was conducted on 2075 B.S. led by Indra
              Bahadur Bista along with consultants from Sanima Hydro and
              Engineering Pvt. Ltd. Over the years, multiple site visits have
              been conducted along with team of experts, engineers and potential
              investors.
            </p>
            {activeTab === "site-visit" && <SiteVisit />}
          </Tab>
          <Tab eventKey="survey" title="Survey">
            <h4 className="ps-2 pe-2">Survey</h4>
            <p className="ps-2 pe-2">
              The Initial Survey License allows assessment and survey of
              feasibility of hydropower generation at proposed project site. .
              It grants permission for preliminary studies, environmental impact
              assessments, and feasibility analysis necessary for the
              development of a hydropower plant. <br />
              The company had obtained initial survey license from Department of
              Electricity Development (DOED) on 10th July, 2018.
            </p>
            {activeTab === "survey" && <Survey />}
          </Tab>
          <Tab eventKey="wfm" title="Water Flow Measurement">
            <h4 className="ps-2 pe-2">Water Flow Measurement</h4>
            <p className="ps-2 pe-2">
              Accurate water flow measurement is crucial for hydropower projects
              to optimize energy production, assess resource availability, and
              manage reservoir levels effectively, ensuring sustainable
              operation and maximizing the efficiency of electricity generation.
              For, this it is important to establish gauge station and measure
              water flow regularly.
            </p>
            {activeTab === "wfm" && <Wfm />}
          </Tab>
          <Tab eventKey="dpr" title="Detailed Project Report(DPR)">
            <h4 className="ps-2 pe-2">Detailed Project Report(DPR)</h4>
            <p className="ps-2 pe-2">
              A Detailed Project Report (DPR) is essential for hydropower
              projects as it outlines feasibility, technical specifications,
              financial viability, and environmental considerations, serving as
              a roadmap for successful implementation. <br />
              The company had entered into the agreement with Sanima Hydro and
              Engineering Pvt. Ltd for consulting services for DPR on 7 April,
              2021. The consultant had submitted the DPR on 16 January, 2023.
            </p>
            {activeTab === "dpr" && <Dpr />}
          </Tab>

          <Tab eventKey="eia" title="EIA">
            <h4 className="ps-2 pe-2">EIA</h4>
            <p className="ps-2 pe-2">
              Approval of Environmental Impact Assessment (EIA) for hydropower
              projects ensures sustainable development by assessing
              environmental impacts, mitigating risks, complying with
              regulations, and gaining public trust for project implementation.{" "}
              <br />
              The EIA was approved from the Ministry of Forests and Environment
              on 14 February, 2023.
            </p>
            {activeTab === "eia" && <Eia />}
          </Tab>
          <Tab eventKey="ppa" title="PPA">
            <h4 className="ps-2 pe-2">PPA</h4>
            <p className="ps-2 pe-2">
              A Power Purchase Agreement (PPA) with the government provides
              hydropower projects stability, ensuring long-term revenue streams,
              incentivizing investment, and securing market access for
              electricity generated. <br />
              The company had entered into the agreement with Nepal Electricity
              Authority (NEA) for PPA on 10 July, 2023.
            </p>
            {activeTab === "ppa" && <Ppa />}
          </Tab>
          <Tab eventKey="gls" title="Generation License">
            <h4 className="ps-2 pe-2">Generation License</h4>
            <p className="ps-2 pe-2">
              Securing an Electricity General License from the Government
              legitimizes hydropower projects, ensuring compliance with
              regulations, facilitating market access, and providing a legal
              framework for electricity generation and distribution. <br />
              The company had obtained Electricity Generation License from the
              Department of Electricity Development of Government of Nepal on 19
              October, 2023.
            </p>
            {activeTab === "gls" && <GLiscense />}
          </Tab>
          <Tab eventKey="public-interaction" title="Public Interaction">
            <h4 className="ps-2 pe-2">Public Interaction Programs</h4>
            <p className="ps-2 pe-2">
              garner community support. Engaging with stakeholders builds trust,
              mitigates conflicts, incorporates local knowledge, and promotes
              sustainable development through informed decision-making and
              inclusive practices. <br />
              Recently, the company had conducted public interaction program at
              project site on 7 October, 2023.
            </p>
            {activeTab === "public-interaction" && <PublicI />}
            <div className="video">
              <Carousel fade indicators={false}>
                <Carousel.Item autoPlay={false}>
                  <video
                    className="videogallery w-100"
                    src="./images/publici/video1.mp4"
                    title="Road Construction"
                    allowFullScreen
                    controls
                  />
                </Carousel.Item>
                <Carousel.Item autoPlay={false}>
                  <video
                    className="videogallery w-100"
                    src="./images/publici/video2.mp4"
                    title="Road Construction"
                    allowFullScreen
                    controls
                  />
                </Carousel.Item>
              </Carousel>
            </div>
          </Tab>
          <Tab eventKey="csp" title="Community Support Program">
            <h4 className="ps-2 pe-2">Community Support Program</h4>
            <p className="ps-2 pe-2">
              Our community support program is dedicated to enhance
              socio-economic condition of local residents at the project area
              and foster positive relationship & collaboration with local
              residents. Through initiatives such as uplifting the standard of a
              government school 'Buddha Aadharbhut Primary School' as our first
              CSP, we aim to improve the quality of education for children at
              project area. Further, our project has been supporting the
              religious programs and 'Pooja' at local pilgrims like Devisthan
              and Dangkhilla Mandir.
            </p>
            {activeTab === "csp" && <Csp />}
          </Tab>
          <Tab eventKey="access-road" title="Access Road">
            <h4 className="ps-2 pe-2">Access Road</h4>
            <p className="ps-2 pe-2">
              Sustainable access road is a primary part of a hydropower project
              which enables easy access to construction site and facilitates
              logistical operation. Most importantly, access road constructed by
              a hydropower project lays a foundation in rural development.{" "}
              <br /> Till March 2024, we constructed a 12 km long access road
              from Budhabare to powerhouse at Beteni. The residents of project
              area are delighted with the convenience obtained from the access
              road constructed by the project.
            </p>
            {activeTab === "access-road" && <AccessRoad />}
          </Tab>
          <Tab eventKey="tsl" title="Transmission Survey License">
            <h4 className="ps-2 pe-2">Transmission Survey License</h4>
            <p className="ps-2 pe-2">
              The transmission survey license enables a project to conduct vital
              assessments for establishing efficient power transmission
              infrastructure. This license ensures seamless integration into the
              grid, maximizing renewable energy distribution. The proposed power
              evacuation line of the project is Sitalpati Substation (132 kV)
              which is around 19 km away from switchyard. The company has
              obtained Transmission Survey License from Department of
              Electricity Development (DOED) on 1st February, 2024.
            </p>
            {activeTab === "tsl" && <Tls />}
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default InnerGallery;
