import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import HeaderFirst from "./HeaderFirst";
import logo from "../../img/logo.png";
import "./style.css";

const Header = () => {
  return (
    <div className="stickey">
      <HeaderFirst />
      <div className="barss ">
        <Navbar collapseOnSelect expand="lg" className="navbars">
          <Link to="/">
            <img src={logo} alt="logo" className="logoh" />
          </Link>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            style={{
              backgroundColor: "#fff",
              padding: "10px",
            }}
          >
            <Nav className="me-auto"></Nav>
            <Nav className="mt-2">
              <Link to="/" className=" text-decoration-none">
                <li className="nav_link text-dark">
                  <span>Home</span>
                </li>
              </Link>
              <li className="nav_link">
                <span>About Us</span>
                <ul className="navdropdown">
                  <Link to="/introduction" className=" text-decoration-none">
                    <li>Introduction</li>
                  </Link>

                  <Link
                    to="/message-from-chairman"
                    className=" text-decoration-none"
                  >
                    <li>Message From Chairman</li>
                  </Link>
                  <Link
                    to="/board-of-directors"
                    className=" text-decoration-none"
                  >
                    <li>Board of Directors</li>
                  </Link>
                  <Link to="/our-team" className=" text-decoration-none">
                    <li>Our Team</li>
                  </Link>
                  <Link to="/consultant" className=" text-decoration-none">
                    <li>Consultant</li>
                  </Link>
                </ul>
              </li>
              {/* <Link to="/projects" className=" text-decoration-none">
                <li className="nav_link text-dark">
                  <span>Projects</span>
                </li>
              </Link> */}
              <li className="nav_link">
                <span>Projects</span>
                <ul className="navdropdown">
                  <Link to="/key-dates" className=" text-decoration-none">
                    <li>Key Dates</li>
                  </Link>
                  <Link to="/projects" className=" text-decoration-none">
                    <li>All Projects</li>
                  </Link>
                </ul>
              </li>
              <li className="nav_link">
                <span>Investor Relations</span>
                <ul className="navdropdown">
                  <Link to="/updates" className=" text-decoration-none">
                    <li>News Coverage</li>
                  </Link>
                  <Link to="/annual-report" className=" text-decoration-none">
                    <li>Annual Report</li>
                  </Link>
                  <Link to="/quaterly-report" className=" text-decoration-none">
                    <li>Quaterly Report</li>
                  </Link>
                </ul>
              </li>
              <Link to="/updates" className=" text-decoration-none text-dark">
                <li className="nav_link gall">
                  <span>Updates</span>
                </li>
              </Link>

              <Link to="/gallery" className=" text-decoration-none text-dark">
                <li className="nav_link gall">
                  <span>Photo Gallery</span>
                </li>
              </Link>
              <Link
                to="/video-gallery"
                className=" text-decoration-none text-dark"
              >
                <li className="nav_link gall">
                  <span>Video Gallery</span>
                </li>
              </Link>
              <li className="nav_link gal">
                <span>Gallery</span>
              </li>
              <li className="nav_link">
                <span>Notices</span>
              </li>
              <Link to="/contact" className=" text-decoration-none">
                <li className="nav_link text-dark">
                  <span>Contact Us</span>
                </li>
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
};

export default Header;
