import React from "react";
import "./style.css";
import "../screens/style.css";

const KeyDates = () => {
  return (
    <div className="intro">
      <div className="back">
        <img src="./images/back.jpg" className="w-100" alt="" />
        <div className="inner-head">
          <h3>Key Dates</h3>
          <span>About Us &gt;&gt;Key Dates</span>
        </div>
      </div>
      <div className="p-lg-5 pt-4 pb-4">
        <div className="col-lg-10 offset-lg-1">
          <div className="key-dates">
            <table>
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Date</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Survey License Issued </td>
                  <td>26th Ashad 2075(10th July 2018)</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Grid Connection Agreement (MOU) </td>
                  <td>10th Ashad 2077 (24th June 2020) </td>
                  <td>Extension of MOU on 14th Jestha 2080 (28th May 2080)</td>
                </tr>
                <tr>
                  <td>Environmental Impact Assessment (EIA) </td>
                  <td>29th Magh 2079 (12th February 2023)</td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    Power Purchase Agreement (PPA)with Nepal Electricity
                    Authority (NEA){" "}
                  </td>
                  <td>25th Ashad 2080 (10th July 2023)</td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    Generation license issued by Department of Electricity
                    Development (DOED)
                  </td>
                  <td>30th Ashwin 2080 (17th October 2023)</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Transmission Line Letter Of Acceptance (LOA) Issued</td>
                  <td>Obtained 18th Magh 2080 (1st February 2024), </td>
                  <td>Issued by DOED on 9th Magh 2080 (23rd January 2024)</td>
                </tr>
                <tr>
                  <td>Required Commercial Operation Date (RCOD)</td>
                  <td>30th Jestha 2085 (12th June 2028) </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeyDates;
