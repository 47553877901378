import React from "react";
import {
  FaPhoneAlt,
  FaEnvelope,
  FaMapMarkerAlt,
  FaFacebookF,
  FaInstagramSquare,
  FaTwitter,
} from "react-icons/fa";
import { RiWhatsappFill } from "react-icons/ri";
import "./style.css";
import logo from "../../img/logo.png";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container pt-5 ">
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="logo">
              <img src={logo} alt="logo" className="logoh pb-2" />

              <p>
                Happy Energy Pvt. Ltd is registered at Office of the Company
                Registrar, Tripureshwor, Kathmandu, Nepal on 06 Jestha, 2075 (20
                May, 2018) as per the provisions of Companies Act, 2063.
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="touch">
              <h4 className="ps-4">Get In Touch!</h4>
              <ul>
                <li>
                  <span>
                    <FaPhoneAlt className="icon" />
                  </span>{" "}
                  014526248
                </li>
                <li>
                  <span>
                    <FaEnvelope className="icon" />
                  </span>{" "}
                  info@happyenergy.com.np
                </li>
                <li>
                  <span>
                    <FaMapMarkerAlt className="icon" />
                  </span>{" "}
                  Naxal Kathmandu, Nepal
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="quick">
              <h4 className="ps-4">Important Links!</h4>
              <ul>
                <li>
                  <a
                    href="https://ocr.gov.np/"
                    className="text-white text-decoration-none"
                    target="__blank"
                  >
                    ocr.gov.np
                  </a>
                </li>
                <li>
                  <a
                    href="http://doed.gov.np/"
                    className="text-white text-decoration-none"
                    target="__blank"
                  >
                    doed.gov.np
                  </a>
                </li>
                <li>
                  <a
                    href="https://nea.org.np/"
                    className="text-white text-decoration-none"
                    target="__blank"
                  >
                    nea.org.np
                  </a>
                </li>
                <li>
                  <a
                    href="https://doind.gov.np/"
                    className="text-white text-decoration-none"
                    target="__blank"
                  >
                    doind.gov.np
                  </a>
                </li>
                <li>
                  <a
                    href="https://moewri.gov.np/"
                    className="text-white text-decoration-none"
                    target="__blank"
                  >
                    moewri.gov.np
                  </a>
                </li>
                <li>
                  <a
                    href="https://nepalstock.com.np/"
                    className="text-white text-decoration-none"
                    target="__blank"
                  >
                    nepalstock.com.np
                  </a>
                </li>
                <li>
                  <a
                    href="https://sebon.gov.np/"
                    className="text-white text-decoration-none"
                    target="__blank"
                  >
                    sebon.gov.np
                  </a>
                </li>
                <li>
                  <a
                    href="https://cdsc.com.np/"
                    className="text-white text-decoration-none"
                    target="__blank"
                  >
                    cdsc.com.np
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="connected">
              <h4 className="ps-4">Stay Connected!</h4>
              <ul>
                <li>
                  <span>
                    <FaFacebookF className="icon" />
                  </span>
                </li>
                <li>
                  <span>
                    <FaInstagramSquare className="icon" />
                  </span>
                </li>
                {/* <li>
                  <span>
                    <FaTwitter className="icon" />
                  </span>
                </li> */}
                <li>
                  <span>
                    <RiWhatsappFill className="icon" />
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr />
        <footer className="text-center pb-3">
          &copy; 2023 happyenergy.com.np All Right Reserved.
        </footer>
      </div>
    </div>
  );
};

export default Footer;
