import React, { useState } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import { gliscenseImages } from "./InnerImage";
import "./style.css";

const gliscenseImagesSlides = gliscenseImages.map(({ original }) => ({
  src: original,
}));

console.log(gliscenseImagesSlides);
const GLiscense = () => {
  const [index, setIndex] = useState(-1);
  const handleClick = (index) => setIndex(index);
  return (
    <div className="pt-4">
      <Gallery
        images={gliscenseImages}
        onClick={handleClick}
        enableImageSelection={false}
      />
      <Lightbox
        slides={gliscenseImagesSlides}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
      />
    </div>
  );
};

export default GLiscense;
