import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "./style.css";

const PopUp = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 3000);
  }, []);

  return (
    <div className="popup">
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body closeButton>
          <img src="./images/dashain.jpg" alt="" />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PopUp;
