import React from "react";
import { Link } from "react-router-dom";

function NavLink() {
  return (
    <div className="admin-nav-link">
      <div className="admin-heads">
        <h4>
          <Link
            to="/dashboard"
            style={{ color: "#fff", textDecoration: "none" }}
          >
            Happy Energy
          </Link>
        </h4>
      </div>
      <div className="btn-group">
        <Link
          to="/all-banners"
          style={{
            color: "#fff",
            textDecoration: "none",
            fontSize: "19px",
            fontWeight: "600",
            paddingLeft: "50px",
          }}
        >
          Banner
        </Link>
      </div>
      <br />
      <div className="btn-group">
        <Link
          to="/all-projects"
          style={{
            color: "#fff",
            textDecoration: "none",
            fontSize: "19px",
            fontWeight: "600",
            paddingLeft: "50px",
          }}
        >
          Project
        </Link>
      </div>
      <br />
      <div className="btn-group">
        <Link
          to="/all-progress-report"
          style={{
            color: "#fff",
            textDecoration: "none",
            fontSize: "19px",
            fontWeight: "600",
            paddingLeft: "50px",
          }}
        >
          Progress Report
        </Link>
      </div>
      <br />
      <div className="btn-group">
        <Link
          to="/all-yearly-reports"
          style={{
            color: "#fff",
            textDecoration: "none",
            fontSize: "19px",
            fontWeight: "600",
            paddingLeft: "50px",
          }}
        >
          Yearly Reprot
        </Link>
      </div>
      <br />
      <div className="btn-group">
        <Link
          to="/all-quaterly-reports"
          style={{
            color: "#fff",
            textDecoration: "none",
            fontSize: "19px",
            fontWeight: "600",
            paddingLeft: "50px",
          }}
        >
          Quaterly Report
        </Link>
        <br />
      </div>
      <div className="btn-group">
        <Link
          to="/all-news"
          style={{
            color: "#fff",
            textDecoration: "none",
            fontSize: "19px",
            fontWeight: "600",
            paddingLeft: "50px",
          }}
        >
          News & Updates
        </Link>
      </div>
      <br />
      <div className="btn-group">
        <Link
          to="/all-photos"
          style={{
            color: "#fff",
            textDecoration: "none",
            fontSize: "19px",
            fontWeight: "600",
            paddingLeft: "50px",
          }}
        >
          Photos
        </Link>
      </div>
      <br />
      <div className="btn-group">
        <Link
          to="/all-contacts"
          style={{
            color: "#fff",
            textDecoration: "none",
            fontSize: "19px",
            fontWeight: "600",
            paddingLeft: "50px",
          }}
        >
          {" "}
          Contacts
        </Link>
      </div>
      <br />
    </div>
  );
}

export default NavLink;
