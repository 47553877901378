import React from "react";
import "./style.css";
import {
  FaPhoneAlt,
  FaEnvelope,
  FaMapMarkerAlt,
  FaFacebookF,
  FaInstagramSquare,
  FaTwitter,
} from "react-icons/fa";
import { RiWhatsappFill } from "react-icons/ri";

const HeaderFirst = () => {
  return (
    <div className="first-nav">
      <div className="first-head">
        <div className="nav-contact">
          <ul>
            <li style={{ borderRight: "1px solid #fff" }}>
              <span>
                <FaPhoneAlt className="icon" />
              </span>{" "}
              014526248
            </li>
            <li style={{ borderRight: "1px solid #fff" }}>
              <span>
                <FaEnvelope className="icon" />
              </span>{" "}
              info@happyenergy.com.np
            </li>
            <li>
              <span>
                <FaMapMarkerAlt className="icon" />
              </span>{" "}
              Naxal Kathmandu, Nepal
            </li>
          </ul>
        </div>
        <div className="nav-social">
          <ul>
            <li>
              <span>
                <FaFacebookF className="icon" />
              </span>
            </li>
            <li>
              <span>
                <FaInstagramSquare className="icon" />
              </span>
            </li>
            {/* <li>
              <span>
                <FaTwitter className="icon" />
              </span>
            </li> */}
            <li>
              <span>
                <RiWhatsappFill className="icon" />
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HeaderFirst;
