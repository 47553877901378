import React from "react";
import { FaRegFilePdf } from "react-icons/fa";
import "./style.css";

const ProgressReport = () => {
  return (
    <div className="intro">
      <div className="back">
        <img src="./images/back.jpg" className="w-100" alt="" />
        <div className="inner-head">
          <h3> Progress Report</h3>
          <span>Investor Relations &gt;&gt; Progress Report</span>
        </div>
      </div>
      <div className="intro-body pcontainer pt-4 pb-4 p-md-5">
        <div className="progress-report">
          <div className="ihead">
            <h2 className="pt-4 pb-4">Progress Report</h2>
          </div>
          <div className="intro-content">
            <div className="row">
              <div className="col-lg-6  mb-3">
                <div className="report-card">
                  <div className="d-flex">
                    <div className="col-2">
                      <div className="picon">
                        <FaRegFilePdf className="pdficon" />
                      </div>
                    </div>
                    <div className="col-10">
                      <div className="card-content p-3">
                        <span>2023/12/13</span>
                        <h4>Lorem ipsum dolor sit amet amet</h4>
                        <span>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit consectetur adipisicing elit
                        </span>
                        <button className="view">View</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6  mb-3">
                <div className="report-card">
                  <div className="d-flex">
                    <div className="col-2">
                      <div className="picon">
                        <FaRegFilePdf className="pdficon" />
                      </div>
                    </div>
                    <div className="col-10">
                      <div className="card-content p-3">
                        <span>2023/12/13</span>
                        <h4>Lorem ipsum dolor sit amet amet</h4>
                        <span>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit consectetur adipisicing elit
                        </span>
                        <button className="view">View</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mb-3">
                <div className="report-card">
                  <div className="d-flex">
                    <div className="col-2">
                      <div className="picon">
                        <FaRegFilePdf className="pdficon" />
                      </div>
                    </div>
                    <div className="col-10">
                      <div className="card-content p-3">
                        <span>2023/12/13</span>
                        <h4>Lorem ipsum dolor sit amet amet</h4>
                        <span>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit consectetur adipisicing elit
                        </span>
                        <button className="view">View</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mb-3">
                <div className="report-card">
                  <div className="d-flex">
                    <div className="col-2">
                      <div className="picon">
                        <FaRegFilePdf className="pdficon" />
                      </div>
                    </div>
                    <div className="col-10">
                      <div className="card-content p-3">
                        <span>2023/12/13</span>
                        <h4>Lorem ipsum dolor sit amet amet</h4>
                        <span>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit consectetur adipisicing elit
                        </span>
                        <button className="view">View</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressReport;
