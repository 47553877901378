import React from "react";
import "./style.css";
import "../screens/style.css";

const OurTeam = () => {
  return (
    <div className="intro">
      <div className="back">
        <img src="./images/back.jpg" className="w-100" alt="" />
        <div className="inner-head">
          <h3>Our Team</h3>
          <span>About Us &gt;&gt; Our Team</span>
        </div>
      </div>
      <div className="p-lg-5 pt-4 pb-4">
        <div className="head pt-lg-3">
          <h3>Technical Team</h3>
        </div>
        <div className="pt-lg-5 bod">
          <div className="col-md-10 offset-md-1">
            <div className="row">
              <div className="col-lg-3">
                <img
                  src="./images/ajeet.jpg"
                  className="w-100 p-2 m-lg-3"
                  alt=""
                />
              </div>
              <div className="col-lg-9 p-md-4">
                <div className="mb-4">
                  <h4>Mr. Ajeet Bikram Karki</h4>
                  {/* <p>
                    <i>Chairperson</i>
                  </p> */}
                  <p className="work_title">Experience:</p>
                  <ul>
                    <li>More than 20 years of experience</li>
                    <li>Hydropower Infrastructure Specialist</li>
                    <li>
                      Completed Upper Dordi ‘A’ Hydroelectric Project (25 MW)
                    </li>
                    <li>Technical Head at Happy Energy Pvt. Ltd.</li>
                  </ul>
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-lg-3">
                <img
                  src="./images/binamra.jpg"
                  className="w-100 p-2 m-lg-3"
                  alt=""
                />
              </div>
              <div className="col-lg-9 p-md-4">
                <div className="mb-4">
                  <h4>Mr. Binamra Shrestha</h4>
                  {/* <p>
                    <i>Director</i>
                  </p> */}
                  <p className="work_title">Experience:</p>
                  <ul>
                    <li>
                      Civil (Site) Engineer at Shiva Shree Hydropopwer Pvt. Ltd
                    </li>
                    <li>
                      Asst. Engineer at Feedback Infrastructure Services Nepal
                      Ltd.
                    </li>
                    <li>
                      Engineer at National Reconstruction Authority, DUDBC
                    </li>
                    <li>
                      Water, Sanitation and Hygiene (WASH) Officer at Nepal Red
                      Cross Society
                    </li>
                    <li>
                      Civil Engineer at Swet Ganga Hydropower &amp; Construction
                      Ltd.
                    </li>
                    <li>Senior Engineer at Happy Energy Pvt. Ltd.</li>
                  </ul>
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-lg-3">
                <img
                  src="./images/kishor.jpg"
                  className="w-100 p-2 m-lg-3"
                  alt=""
                />
              </div>
              <div className="col-lg-9 p-md-4">
                <div className="mb-4">
                  <h4>Mr. Kishor Bista</h4>
                  {/* <p>
                    <i>Director</i>
                  </p> */}
                  <p className="work_title">Experience:</p>
                  <ul>
                    <li>Civil Engineer at Sujata Construction Pvt. Ltd.</li>
                    <li>Civil Engineer at Happy Energy Pvt. Ltd.</li>
                  </ul>
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>
        <div className="head pt-lg-3">
          <h3>Account and Finance Team</h3>
        </div>
        <div className="pt-lg-5 bod">
          <div className="col-md-10 offset-md-1">
            <div className="row">
              <div className="col-lg-3">
                <img
                  src="./images/mahesh.jpg"
                  className="w-100 p-2 m-lg-3"
                  alt=""
                />
              </div>
              <div className="col-lg-9 p-md-4">
                <div className="mb-4">
                  <h4>Mr. Mahesh Prasad Kafle</h4>
                  {/* <p>
                    <i>Chairperson</i>
                  </p> */}
                  <p className="work_title">Experience:</p>
                  <ul>
                    <li>
                      Director at Garima Capital Ltd. Website:
                      https://garimacapital.com/board-of-directors
                    </li>
                    <li>Former CEO of Kamana Sewa Bikash Bank Ltd.</li>
                    <li>Former-Director: Sahara Bikas Bank Ltd.</li>
                    <li>Former Campus Chief: Achham Multiple Campus</li>
                    <li>
                      Former-Director: Chautari Microfinance Institution Ltd.
                    </li>
                    <li>Executive Chairman: Kings Holiday Pvt. Ltd.</li>
                    <li>
                      Executive Chairman: Sewa Investment &amp; International
                      Group Pvt. Ltd.
                    </li>
                    <li>
                      Director: SP Group of Engineering &amp; Associates Pvt.
                      Ltd.
                    </li>
                    <li>Chairman: Blooming Engineering Consult Pvt. Ltd.</li>
                  </ul>
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-lg-3">
                <img
                  src="./images/tika.jpg"
                  className="w-100 p-2 m-lg-3"
                  alt=""
                />
              </div>
              <div className="col-lg-9 p-md-4">
                <div className="mb-4">
                  <h4>Mr. Tika Ram Bista</h4>
                  {/* <p>
                    <i>Director</i>
                  </p> */}
                  <p className="work_title">Experience:</p>
                  <ul>
                    <li>Director- Finance at Happy Energy Pvt. Ltd.</li>
                    <li>Qualification: BBS (TU), CA Semi Qualified (ICAN)</li>
                    <li>
                      Former Company Secretary and Compliance Officer at
                      Taragaon Regency Hotels Ltd. (Hyatt Regency Kathmandu)
                    </li>
                    <li>
                      Over a decade of work experience in the field of accounts,
                      laws, taxation, audit and finance.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-lg-3">
                <img
                  src="./images/atit.jpg"
                  className="w-100 p-2 m-lg-3"
                  alt=""
                />
              </div>
              <div className="col-lg-9 p-md-4">
                <div className="mb-4">
                  <h4>Mr. Atit Laudari</h4>
                  {/* <p>
                    <i>Director</i>
                  </p> */}
                  <p className="work_title">Experience:</p>
                  <ul>
                    <li>
                      Semi qualified Chartered Accountant under Institute of
                      Chartered Accountants of Nepal (ICAN)
                    </li>
                    <li>
                      Completed Articleship from JBRH &amp; Co., Chartered
                      Accountants under ICAN
                    </li>
                    <li>
                      Former Audit Associate at JBRH &amp; Co., Chartered
                      Accountants
                    </li>
                    <li>Account Officer at Happy Energy Pvt. Ltd.</li>
                  </ul>
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
