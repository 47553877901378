export const CREATE_ERRORS = "CREATE_ERRORS";
export const REMOVE_ERRORS = "REMOVE_ERRORS";
export const SET_LOADER = "SET_LOADER";
export const CLOSE_LOADER = "CLOSE_LOADER";
export const REDIRECT_TRUE = "REDIRECT_TURE";
export const REDIRECT_FALSE = "REDIRECT_FALSE";
export const SET_MESSAGE = "SET_MESSAGE";
export const REMOVE_MESSAGE = "REMOVE_MESSAGE";
export const SET_UPDATE_ERRORS = " SET_UPDATE_ERRORS";
export const RESET_UPDATE_ERRORS = " RESET_UPDATE_ERRORS";
export const UPDATE_IMAGE_ERRORS = " UPDATE_IMAGE_ERRORS";
export const RESET_UPDATE_IMAGE_ERRORS = " RESET_UPDATE_IMAGE_ERRORS";
export const SET_PROJECTS = " SET_PROJECTS";
export const SET_PROJECT = " SET_PROJECT";
export const SET_DETAILS = " SET_DETAILS";
export const PROJECT_RESET = " PROJECCT_RESET";
export const PROJECT_REQUEST = "PROJECT_REQUEST";
export const SET_BANNERS = " SET_BANNERS";
