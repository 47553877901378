import React from "react";
import "./style.css";
import "../screens/style.css";

const BoardOfDirector = () => {
  return (
    <div className="intro">
      <div className="back">
        <img src="./images/back.jpg" className="w-100" alt="" />
        <div className="inner-head">
          <h3> Board Of Director</h3>
          <span>About Us &gt;&gt; Board of Director</span>
        </div>
      </div>
      <div className="p-lg-5 pt-4 pb-4">
        <div className="head pt-lg-3">
          <h3> Board of Directors</h3>
        </div>
        <div className="pt-lg-5 bod">
          <div className="col-md-10 offset-md-1">
            <div className="row">
              <div className="col-lg-3">
                <img
                  src="./images/indrasir.jpg"
                  className="w-100 p-2 m-lg-3"
                  alt=""
                />
              </div>
              <div className="col-lg-9 p-md-4">
                <div className="mb-4">
                  <h4>Mr. Indra Bahadur Bista</h4>
                  <p>
                    <i>Chairperson</i>
                  </p>
                  <p className="work_title">Experience:</p>
                  <ul>
                    <li>
                      Executive Chairman- Happy Energy Pvt. Ltd. Website:
                      https://www.happyenergy.com.np/
                    </li>
                    <li>
                      Chairman- Great Hydropower Pvt. Ltd. (Upper Barun Khola
                      Hydropower Project (109 MW).
                    </li>
                    {/* <li>
                      Chairman- Saphal Hydropower Pvt. Ltd. (Panchapokhari Khola
                      Hydropower Project (9 MW)).
                    </li> */}
                    <li>
                      Chairman- Happy Power Pvt. Ltd. (Koluwa Khola Hydropower
                      Project (4.55 MW)
                    </li>
                    <li>Director- Axis College, Butwal.</li>
                    <li>Chairman- I.B. Multipurpose Pvt. Ltd.</li>
                    <li>Chairman- Happy Nepal Investment Pvt. Ltd.</li>
                    <li>Director- Happy Green Gen Nepal Pvt. Ltd.</li>
                  </ul>
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-lg-3">
                <img
                  src="./images/purna.jpg"
                  className="w-100 p-2 m-lg-3"
                  alt=""
                />
              </div>
              <div className="col-lg-9 p-md-4">
                <div className="mb-4">
                  <h4>Mr. Purna Lal Shrestha</h4>
                  <p>
                    <i>Director</i>
                  </p>
                  <p className="work_title">Experience:</p>
                  <ul>
                    <li>
                      Chairman- Buddha Bhumi Nepal Hydro Power Company Ltd. (5
                      MW in operation since last 2 years)
                      Website:https://buddhabhuminepalhydro.com.np/
                    </li>
                    <li>Chairman- Shree Sampanna Sub Remit Pvt. Ltd.</li>
                    <li>Past Director- National Dairy Development Board</li>
                    <li>Director - Chamati Land Pooling Project</li>
                    <li>Director- Happy Energy Pvt. Ltd.</li>
                    <li>Chairman- Happy Green Gen Nepal Pvt. Ltd.</li>
                  </ul>
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-lg-3">
                <img
                  src="./images/badri.jpg"
                  className="w-100 p-2 m-lg-3"
                  alt=""
                />
              </div>
              <div className="col-lg-9 p-md-4">
                <div className="mb-4">
                  <h4>Dr. Badri KC</h4>
                  <p>
                    <i>Director</i>
                  </p>
                  <p className="work_title">Experience:</p>
                  <ul>
                    <li>
                      Chairman, Samyukta Urja Pvt. Ltd. developing Thulo Khola
                      HEP-21.3 MW.
                    </li>
                    <li>Chairman , Sayapatri Hydropower Limited</li>
                    <li>Board of Director, Continental Teas and Exports</li>
                    <li>Board of Director, Nature Himalaya Teas and Exports</li>
                    <li>
                      Board of Director, Nepal Payment Solution (an integrated
                      payment gateway licensed by Nepal Rastra Bank.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col-lg-3">
                <img
                  src="./images/tika.jpg"
                  className="w-100 p-2 m-lg-3"
                  alt=""
                />
              </div>
              <div className="col-lg-9 p-md-4">
                <div className="mb-4">
                  <h4>Mr. Tika Ram Bista</h4>
                  <p>
                    <i>Director</i>
                  </p>
                  <p className="work_title">Experience:</p>
                  <ul>
                    <li>Director- Finance at Happy Energy Pvt. Ltd.</li>
                    <li>Qualification: BBS (TU), CA Semi Qualified (ICAN)</li>
                    <li>
                      Former Company Secretary and Compliance Officer at
                      Taragaon Regency Hotels Ltd. (Hyatt Regency Kathmandu)
                    </li>
                    <li>
                      Over a decade of work experience in the field of accounts,
                      laws, taxation, audit and finance.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-lg-3">
                <img
                  src="./images/rajendra.jpg"
                  className="w-100 p-2 m-lg-3"
                  alt=""
                />
              </div>
              <div className="col-lg-9 p-md-4">
                <div className="mb-4">
                  <h4>Mr. Rajendra Osti</h4>
                  <p>
                    <i>Director</i>
                  </p>
                  <p className="work_title">Experience:</p>
                  <ul>
                    <li>
                      Director, Liberty Energy Company Ltd., developer of 25 MW
                      Upper Dordi ‘A’ HEP.
                    </li>
                    <li>
                      Former Chairman, Ambe Hydropower Pvt. Ltd., Upper
                      Bhurundi Khola HEP
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoardOfDirector;
