import React, { useState } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import { siteVisitImages } from "./InnerImage";
import "./style.css";

const sitevisitSlides = siteVisitImages.map(({ original }) => ({
  src: original,
}));

console.log(sitevisitSlides);
const SiteVisit = () => {
  const [index, setIndex] = useState(-1);
  const handleClick = (index) => setIndex(index);
  return (
    <div className="pt-4">
      <Gallery
        images={siteVisitImages}
        onClick={handleClick}
        enableImageSelection={false}
      />
      <Lightbox
        slides={sitevisitSlides}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
      />
    </div>
  );
};

export default SiteVisit;
