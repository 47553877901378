import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { REMOVE_MESSAGE } from "../../../../store/types/Types";
import { bannerCreate } from "../../../../store/asyncAction/BannerAction";
import "../style.css";

function AddBanner() {
  const { createErrors, redirect, message } = useSelector(
    (state) => state.Reducers
  );
  const [currentImage, setCurrentImage] = useState("Choose Image");
  const [imagePreview, setImagePreview] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [state, setState] = useState("");

  const flieHandle = (e) => {
    if (e.target.files.length !== 0) {
      setCurrentImage(e.target.files[0].name);
      setState({
        ...state,
        [e.target.name]: e.target.files[0],
      });
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const createBanner = (e) => {
    e.preventDefault();
    const { image } = state;
    const formData = new FormData();
    formData.append("image", image);
    dispatch(bannerCreate(formData));
  };
  useEffect(() => {
    if (redirect) {
      navigate("/add-banner");
      window.location.reload("/add-banner");
    }
    if (createErrors.length > 0) {
      createErrors.map((error) => toast.error(error.msg));
    }
    if (message) {
      toast.success(message);
      dispatch({ type: REMOVE_MESSAGE });
      setImagePreview();
      setCurrentImage("Choose Image");
      setState();
    }
  }, [createErrors, redirect, message, dispatch]);
  return (
    <div className="create-product-section">
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          style: {
            fontSize: "14px",
          },
        }}
      />
      <form onSubmit={createBanner} className="col-lg-10 offset-1 p-5">
        <div className="backs">
          <Link to="/all-banners">
            <p>Back</p>
          </Link>
        </div>
        <center>
          <div className="col-md-6">
            <h2 id="pform">
              <span>Add Banner</span>
            </h2>
            <div className="product-form">
              <div className="mb-3">
                <label className="form-label">Image </label>
                <input
                  type="file"
                  className="form-control"
                  aria-describedby="emailHelp"
                  id="image"
                  name="image"
                  onChange={flieHandle}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="product-slug pt-5">
              <div className="mb-3">
                {imagePreview ? (
                  <img src={imagePreview} alt="book" className="w-100" />
                ) : (
                  <img src={currentImage} alt="banner" className="w-100" />
                )}
              </div>
              <div className="mb-3">
                <input
                  type="submit"
                  className="form-control create-button"
                  aria-describedby="emailHelp"
                  value="Add Banner"
                />
              </div>
            </div>
          </div>
        </center>
      </form>
    </div>
  );
}

export default AddBanner;
