import React from "react";
import "./style.css";
import img1 from "../../img/silent/Salient Features_USHPP_1.jpg";
import img2 from "../../img/silent/Salient Features_USHPP_2.jpg";
import img3 from "../../img/silent/Salient Features_USHPP_3.jpg";
import img4 from "../../img/silent/Salient Features_USHPP_4.jpg";
import img5 from "../../img/silent/Salient Features_USHPP_5.jpg";
import img6 from "../../img/silent/Salient Features_USHPP_6.jpg";
import img7 from "../../img/silent/Salient Features_USHPP_7.jpg";
import img8 from "../../img/silent/Salient Features_USHPP_8.jpg";

const PorjectDetail = () => {
  return (
    <div className="intro">
      <div className="back">
        <img src="./images/back.jpg" className="w-100" alt="" />
        <div className="detail-inner-head ">
          <h3> Projects</h3>
          <span>
            Project Details &gt;&gt; Upper Sankhuwa Khola Hydroelectric Project
          </span>
        </div>
      </div>
      <div className="intro-body pt-4 pb-4 p-md-5">
        <div className="col-lg-8 offset-lg-2 p-3">
          <div className="ihead">
            <h2 className="pt-4 pb-4">
              Upper Sankhuwa Khola Hydroelectric Project
            </h2>
          </div>
          <div className="intro-content detail">
            <img src={img1} className="w-100" alt="" />
            <img src={img2} className="w-100" alt="" />
            <img src={img3} className="w-100" alt="" />
            <img src={img4} className="w-100" alt="" />
            <img src={img5} className="w-100" alt="" />
            <img src={img6} className="w-100" alt="" />
            <img src={img7} className="w-100" alt="" />
            <img src={img8} className="w-100" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PorjectDetail;
