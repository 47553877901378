import React from "react";
import "./style.css";
import "../screens/style.css";

const Consultant = () => {
  return (
    <div className="intro">
      <div className="back">
        <img src="./images/back.jpg" className="w-100" alt="" />
        <div className="inner-head">
          <h3>Consultant</h3>
          <span>About Us &gt;&gt;Consultant</span>
        </div>
      </div>
      <div className="p-lg-5 pt-4 pb-4">
        <div className="col-lg-10 offset-lg-1">
          <div className="consultant">
            <table>
              <thead>
                <tr>
                  <th colSpan={2}>Technical Consultant</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Detailed Feasibility Study Report</td>
                  <td>Excel Engineering Consult Pvt. Ltd.</td>
                </tr>
                <tr>
                  <td>Feasibility Study Review Report</td>
                  <td>Sanima Hydro and Engineering Pvt. Ltd.</td>
                </tr>
                <tr>
                  <td>Detailed Project Report</td>
                  <td>
                    Sanima Hydro and Engineering Pvt.Ltd. (Team Led by Mr. Ajoy
                    Karki)
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            <table>
              <thead>
                <tr>
                  <th colSpan={2}>Financial Closure Consultant</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Ananda Raj Sharma Wagle</td>
                  <td>
                    Partner at Wagle &amp; Associates Chartered Accountants
                  </td>
                </tr>
                <tr>
                  <td>Hemant Katuwal</td>
                  <td>
                    Partner at Wagle &amp; Associates Chartered Accountants
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Consultant;
