export const siteVisitImages = [
  {
    src: "./images/sitevisit/img1.jpg",
    original: "./images/sitevisit/img1.jpg",
  },
  {
    src: "./images/sitevisit/img2.jpg",
    original: "./images/sitevisit/img2.jpg",
  },
  {
    src: "./images/sitevisit/img3.jpg",
    original: "./images/sitevisit/img3.jpg",
  },
  {
    src: "./images/sitevisit/img4.jpg",
    original: "./images/sitevisit/img4.jpg",
  },
  {
    src: "./images/sitevisit/img5.jpg",
    original: "./images/sitevisit/img5.jpg",
  },
  {
    src: "./images/sitevisit/img6.jpeg",
    original: "./images/sitevisit/img6.jpeg",
  },
];

export const survayImages = [
  {
    src: "./images/survay/img.jpg",
    original: "./images/survay/img.jpg",
  },
  {
    src: "./images/survay/img3.jpeg",
    original: "./images/survay/img3.jpeg",
  },
  {
    src: "./images/survay/img4.jpeg",
    original: "./images/survay/img4.jpeg",
  },
];

export const dprImages = [
  {
    src: "./images/dpr/img1.jpeg",
    original: "./images/dpr/img1.jpeg",
  },
  {
    src: "./images/dpr/img2.jpg",
    original: "./images/dpr/img2.jpg",
  },
];

export const wfmImages = [
  {
    src: "./images/wfm/img2.jpg",
    original: "./images/wfm/img2.jpg",
  },
  {
    src: "./images/wfm/img1.jpg",
    original: "./images/wfm/img1.jpg",
  },
  {
    src: "./images/wfm/img3.jpg",
    original: "./images/wfm/img3.jpg",
  },
];

export const gliscenseImages = [
  {
    src: "./images/gliscense/gl.jpeg",
    original: "./images/gliscense/gl.jpeg",
  },
];

export const eiaImages = [
  {
    src: "./images/eia/img0.jpg",
    original: "./images/eia/img0.jpg",
  },
  {
    src: "./images/eia/img1.jpg",
    original: "./images/eia/img1.jpg",
  },
  {
    src: "./images/eia/img2.jpg",
    original: "./images/eia/img2.jpg",
  },
  {
    src: "./images/eia/img3.jpg",
    original: "./images/eia/img3.jpg",
  },
  {
    src: "./images/eia/img4.jpg",
    original: "./images/eia/img4.jpg",
  },
];

export const ppaImages = [
  {
    src: "./images/ppa/ppa1.jpg",
    original: "./images/ppa/ppa1.jpg",
  },
  {
    src: "./images/ppa/ppa2.jpg",
    original: "./images/ppa/ppa2.jpg",
  },
];

export const publiciImages = [
  {
    src: "./images/publici/img1.jpeg",
    original: "./images/publici/img1.jpeg",
  },
  {
    src: "./images/publici/img2.jpeg",
    original: "./images/publici/img2.jpeg",
  },
  {
    src: "./images/publici/img3.jpeg",
    original: "./images/publici/img3.jpeg",
  },
];

export const cspImages = [
  {
    src: "./images/csp/img1.jpeg",
    original: "./images/csp/img1.jpeg",
  },
  {
    src: "./images/csp/img2.jpeg",
    original: "./images/csp/img2.jpeg",
  },
  {
    src: "./images/csp/img3.jpeg",
    original: "./images/csp/img3.jpeg",
  },
];

export const accessRoadImages = [
  {
    src: "./images/accessroad/img3.jpeg",
    original: "./images/accessroad/img3.jpeg",
  },
  {
    src: "./images/accessroad/img1.jpeg",
    original: "./images/accessroad/img1.jpeg",
  },
  {
    src: "./images/accessroad/img2.jpeg",
    original: "./images/accessroad/img2.jpeg",
  },
  {
    src: "./images/accessroad/ar1.jpeg",
    original: "./images/accessroad/ar1.jpeg",
  },
  {
    src: "./images/accessroad/ar2.jpeg",
    original: "./images/accessroad/ar2.jpeg",
  },
  {
    src: "./images/accessroad/ar3.jpeg",
    original: "./images/accessroad/ar3.jpeg",
  },
];

export const tlsImages = [
  {
    src: "./images/tls/img1.jpeg",
    original: "./images/tls/img1.jpeg",
  },
];
