import React, { useState } from "react";
import "./style.css";
import "../screens/style.css";

const MessageCm = () => {
  const [display, setDisplay] = useState(false);
  return (
    <div className="intro">
      <div className="back">
        <img src="./images/back.jpg" className="w-100" alt="" />
        <div className="inner-head">
          <h3> Message From Chairman</h3>
          <span>About Us &gt;&gt; Message from Chairman</span>
        </div>
      </div>
      <div className="message p-lg-5 pt-4 pb-4">
        <div className="head pt-lg-3">
          <h3>Message From Chairman</h3>
        </div>
        <div className="chairman pt-5">
          <div className="col-lg-6 p-md-4">
            <div className="name mb-4">
              <h3>Mr. Indra Bahadur Bista</h3>
              <p>
                <i>Chairman, Happy Energy Pvt. Ltd.</i>
              </p>
            </div>
            <div className="message pt-2">
              <p>
                <em>Greetings!!!</em> <br />
                <strong>
                  Firstly, let me thank you for visiting Happy Energy Pvt Ltd.
                </strong>{" "}
                <br />
                There's no shortage of remarkable ideas, what's missing is the
                will to execute them. With the will of contributing towards
                sustainable development and productive economy for the
                foundation of Prosperous Nepal and Happy Nepali, a small effort
                has been made to identify, evaluate and develop technical and
                financially viable Hydro Electric Projects.
              </p>
              {display ? (
                <p>
                  Accordingly, Happy Energy Pvt. Ltd. has been incorporated with
                  the objective of developing technical and economically
                  feasible Hydro Electric Projects in the various geographical
                  location of the Nepal. <br />
                  Nepal is rich in water resources, with one of the highest per
                  capita hydropower potentials in the world, where more than
                  6000 rivers and rivulets with steep topography offers
                  significant potential for hydropower generation. Based on
                  water resource availability, Nepal's technical potential for
                  the hydropower generation has been estimated to be 83,000
                  Megawatts (MW) whereas only 42,000 MW is economically
                  feasible. As of now, Nepal is developing around 3129 MW and
                  rest of the capacity is untapped. The development of
                  hydropower project creates employment opportunity, facilitates
                  Nepal Government for foreign currency earning and ultimately
                  helps to economic development of the nation. Most of the
                  hydropower projects in Nepal are profitable so, we have an
                  opportunity to invest, earn profit and contribute towards the
                  nation. <br />
                  It's my immense pleasure to announce that as the First Project
                  of the company, we are developing Upper Sankhuwa Khola Hydro
                  Electric Project of 40 MW capacity.
                  <br />
                  I cordially invite prospective national & foreign investors
                  and stakeholdersto join hands and work together for the
                  successful completion of the project. <br />
                  <em>
                    Looking forward for longer and stronger business relation.
                  </em>
                </p>
              ) : (
                ""
              )}
              <div className="read-more-w pt-2">
                <button onClick={() => setDisplay(true)}>Read More</button>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <img
              src="./images/indrasir.jpg"
              className="w-100 p-2 m-lg-3"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageCm;
