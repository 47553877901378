import axios from "axios";
import {
  SET_LOADER,
  CLOSE_LOADER,
  CREATE_ERRORS,
  REDIRECT_TRUE,
  SET_MESSAGE,
  REMOVE_ERRORS,
  SET_BANNERS,
} from "../types/Types";

// banners
export const bannerCreate = (postData) => {
  return async (dispatch, getState) => {
    const {
      AuthReducer: { token },
    } = getState();
    dispatch({ type: SET_LOADER });
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const {
        data: { msg },
      } = await axios.post("/api/banner", postData, config);
      dispatch({ type: CLOSE_LOADER });
      dispatch({ type: REMOVE_ERRORS });
      dispatch({ type: REDIRECT_TRUE });
      dispatch({ type: SET_MESSAGE, payload: msg });
    } catch (error) {
      console.log(error.response);
      const errors = error.response.data;
      dispatch({ type: CLOSE_LOADER });
      dispatch({ type: CREATE_ERRORS, payload: errors });
    }
  };
};

export const fetchBanners = () => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_LOADER });
    try {
      const {
        data: { response },
      } = await axios.get("/api/banners");
      dispatch({ type: CLOSE_LOADER });
      dispatch({ type: SET_BANNERS, payload: { response } });
    } catch (error) {
      dispatch({ type: CLOSE_LOADER });
    }
  };
};
