import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useSelector, useDispatch } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { createProject } from "../../../../store/asyncAction/ProjectAction";
import { REMOVE_MESSAGE } from "../../../../store/types/Types";
import EditorToolbar, { modules, formats } from "../Quil";

const AddProject = () => {
  const { createErrors, redirect, message } = useSelector(
    (state) => state.Reducers
  );
  const [currentImage, setCurrentImage] = useState("Choose Image");
  const [imagePreview, setImagePreview] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { user: { _id, name } } = useSelector(state => state.AuthReducer);
  const flieHandle = (e) => {
    if (e.target.files.length !== 0) {
      setCurrentImage(e.target.files[0].name);
      setState({
        ...state,
        [e.target.name]: e.target.files[0],
      });
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const [state, setState] = useState({
    title: "",
    completed: "",
  });

  const handleInput = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const [value, setValue] = useState("");

  const projectCreate = (e) => {
    e.preventDefault();
    const { title, image, completed } = state;
    const formData = new FormData();
    formData.append("title", title);
    formData.append("completed", completed);
    formData.append("body", value);
    formData.append("image", image);
    dispatch(createProject(formData));
  };
  useEffect(() => {
    if (redirect) {
      navigate("/add-project");
      //   window.location.navigate("/add-project");
    }
    if (createErrors.length > 0) {
      createErrors.map((error) => toast.error(error.msg));
    }
    if (message) {
      toast.success(message);
      dispatch({ type: REMOVE_MESSAGE });
    }
  }, [createErrors, redirect, message, dispatch]);
  return (
    <div className="create-section col-md-10 offset-md-1 p-3 pt-5">
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          style: {
            fontSize: "14px",
          },
        }}
      />
      <form onSubmit={projectCreate}>
        <div className="backs">
          <Link to="/all-projects">
            <p>Back</p>
          </Link>
        </div>
        <div className="row">
          <div className="col-md-6">
            <h2 id="pform">
              <span>Add Project</span>
            </h2>
            <div className="product-form">
              <div className="mb-3">
                <label className="form-label"> Title</label>
                <input
                  type="text"
                  className="form-control"
                  aria-describedby="emailHelp"
                  id="title"
                  name="title"
                  value={state.title}
                  onChange={handleInput}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Image </label>
                <input
                  type="file"
                  className="form-control"
                  aria-describedby="emailHelp"
                  id="image"
                  name="image"
                  onChange={flieHandle}
                />
              </div>
              <div className="mb-3">
                <label>Is Completed?</label>
                <select
                  name="completed"
                  className="form-control"
                  onChange={handleInput}
                >
                  <option>Select Option</option>
                  <option value="No" id="completed">
                    No
                  </option>
                  <option value="Yes" id="completed">
                    Yes
                  </option>
                </select>
              </div>
              <div className="mb-3">
                <label className="form-label"> Description</label>
                <EditorToolbar toolbarId={"t1"} />
                <ReactQuill
                  theme="snow"
                  id="body"
                  value={value}
                  onChange={setValue}
                  placeholder={"Write something awesome..."}
                  modules={modules("t1")}
                  formats={formats}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="product-slug pt-5">
              <div className="mb-3">
                {imagePreview ? (
                  <img src={imagePreview} alt="news" className="w-100" />
                ) : (
                  <img src={currentImage} alt="news" className="w-100" />
                )}
              </div>
              <div className="mb-3">
                <input
                  type="submit"
                  className="form-control create-button"
                  aria-describedby="emailHelp"
                  value="Create Project"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddProject;
