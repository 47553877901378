import React from "react";
import "./style.css";
import { Link } from "react-router-dom";

const Projects = () => {
  return (
    <div className="intro">
      <div className="back">
        <img src="./images/back.jpg" className="w-100" alt="" />
        <div className="inner-head">
          <h3 className="mb-4"> Projects</h3>
        </div>
      </div>
      <div className="intro-body pt-4 pb-4 p-md-5">
        <div className="project p-3">
          <div className="ihead">
            <h2 className="pt-4 pb-2">Projects</h2>
          </div>
          <div className="intro-content">
            <ul>
              <Link
                to="/project-details/Upper-Sankhuwa-Khola-Hydroelectric-Project"
                className="text-dark"
              >
                <li>Upper Sankhuwa Khola Hydroelectric Project</li>
              </Link>
            </ul>
          </div>
          <div className="ongoing">
            <h4>Ready for construction process</h4>
            <ul type="square">
              <Link
                to="/project-details/Upper-Sankhuwa-Khola-Hydroelectric-Project"
                className="text-dark"
              >
                <li>Upper Sankhuwa Khola Hydroelectric Project</li>
              </Link>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
