import React from "react";
import "./style.css";

const News = () => {
  return (
    <div className="intro">
      <div className="back">
        <img src="./images/back.jpg" className="w-100" alt="" />
        <div className="inner-head">
          <h3> News</h3>
        </div>
      </div>
      <div className="updates pt-lg-5 pt-3 ">
        <div className="col-lg-4 col-md-6">
          <div className="magazine-card m-lg-3">
            <div className="magazine-image">
              <img src="./images/news1.jpg" className="w-100" alt="" />
            </div>
            <div className="magazine-content">
              <span className="date">२०८० असार २५</span>
              <h5>
                ४० मेगावाटको माथिल्लो संखुवा खाेलाको पिपिए, ५ वर्षभित्र निर्माण
                सम्पन्न गर्ने लक्ष्य
              </h5>
              <p>
                काठमाडौं । ह्याप्पी इनर्जी प्रालिले संखुवासभामा निर्माण गर्न
                लागेको ४० मेगावाटको माथिल्लो संखुवा खोला जलविद्युत आयोजनाको
                विद्युत खरिद सम्झौत (पिपिए) मा हस्ताक्षर ...
              </p>
              <p className="read">
                {" "}
                <a
                  href="https://www.urjakhabar.com/news/1007684837"
                  target="__blank"
                  className="news-read"
                >
                  <span>Read article</span> &gt;
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="magazine-card m-lg-3">
            <div className="magazine-image">
              <img src="./images/news2.jpg" className="w-100" alt="" />
            </div>
            <div className="magazine-content">
              <span className="date"> November 12, 2022</span>
              <h5>
                Happy Energy to build 40 MW Upper Sankhuwa Khola Hydropower
                Project
              </h5>
              <p>
                KATHMANDU, Nov 12: Happy Energy Pvt Ltd is constructing 40 MW
                Upper Sankhuwa Khola Hydropower Project (USKHP) ...
              </p>
              <p className="read">
                {" "}
                <a
                  href="https://myrepublica.nagariknetwork.com/news/happy-energy-to-build-40-mw-upper-sankhuwa-khola-hydropower-project/"
                  target="__blank"
                  className="news-read"
                >
                  <span>Read article</span> &gt;
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;
