// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../img/dm3.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".login {\n  height: 100vh;\n  background-color: rgba(7, 7, 99, 0.945);\n}\n.login-section {\n  padding-top: 150px;\n  height: 100vh;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  background-size: cover;\n  opacity: 0.7;\n}\n.login-form {\n  background-color: #fff;\n  padding: 50px;\n}\n.form-control:focus {\n  color: #212529;\n  background-color: #fff;\n  border-color: none;\n  outline: 0;\n  box-shadow: none;\n}\n.login-form button {\n  background-color: brown;\n  color: #fff;\n  font-size: 19px;\n  padding: 4px 20px;\n  border: none;\n}\n", "",{"version":3,"sources":["webpack://src/pages/user/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uCAAuC;AACzC;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,6DAA8C;EAC9C,sBAAsB;EACtB,YAAY;AACd;AACA;EACE,sBAAsB;EACtB,aAAa;AACf;AACA;EACE,cAAc;EACd,sBAAsB;EACtB,kBAAkB;EAClB,UAAU;EACV,gBAAgB;AAClB;AACA;EACE,uBAAuB;EACvB,WAAW;EACX,eAAe;EACf,iBAAiB;EACjB,YAAY;AACd","sourcesContent":[".login {\n  height: 100vh;\n  background-color: rgba(7, 7, 99, 0.945);\n}\n.login-section {\n  padding-top: 150px;\n  height: 100vh;\n  background: url(\"../../img/dm3.png\") no-repeat;\n  background-size: cover;\n  opacity: 0.7;\n}\n.login-form {\n  background-color: #fff;\n  padding: 50px;\n}\n.form-control:focus {\n  color: #212529;\n  background-color: #fff;\n  border-color: none;\n  outline: 0;\n  box-shadow: none;\n}\n.login-form button {\n  background-color: brown;\n  color: #fff;\n  font-size: 19px;\n  padding: 4px 20px;\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
